import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SearchContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 30%; // Adjust the width based on your layout
  padding: 10px;
  background-color: #06060e;
  border-left: 1px solid orange;
  z-index: 1000;
  @media (max-width: 768px) {
    display: none; // Hide search bar on mobile
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid orange;
  background-color: #111;
  color: white;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: #ffba3b;
  }
`;

const SearchButton = styled.button`
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: orange;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #ffba3b;
  }
`;

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <SearchContainer>
      <SearchInput
        type="text"
        placeholder="Search for posts or users..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <SearchButton onClick={handleSearch}>Search</SearchButton>
    </SearchContainer>
  );
};

export default SearchBar;