import React, { useEffect, useState } from 'react';
import { useRef } from 'react'; 
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import PostList from './PostList';
import config from './config';
import Switch from "react-switch";
import { useNavigate } from 'react-router-dom';
import MobileNav from './MobileNav';
import Navigation from './Navigation';
import SearchBar from './SearchBar';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto !important;
  border: 1px solid orange;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  max-width: calc(100% - 42px); // I don't know why this is necessary, but it is.
  background-color: #06060e;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const TextBox = styled.textarea`
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  background-color: #111;
  border: none;
  outline: none;
  resize: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;

  &::placeholder {
    color: lightgrey;
  }
`;

const PostButton = styled.button`
  padding: 10px 20px;
  background-color: orange;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.1s ease-in-out;
  
  &:hover {
    background-color: #ffba3b;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const PostContainer = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const UploadButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: orange;
  border: none;
  font-size: 1.5em;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  cursor: pointer;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const ToggleLabel = styled.label`
  margin-right: 5px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transition: .4s;
  border: 2px solid orange;
  border-radius: 34px; // make it fully rounded

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%; // make it a circle
  }

  ${ToggleInput}:checked + & {
    background-color: #6e4709;
  }

  ${ToggleInput}:checked + &:before {
    transform: translateX(24px);
  }

  ${ToggleInput}:focus + & {
    box-shadow: 0 0 1px #2196F3;
  }

  ${ToggleInput}:active + & {
    background-color: #000;
  }
`;

const VideoPreview = styled.video`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  cursor: pointer;
`;



const Explore = () => {
  const [data, setData] = useState({posts: [], users: {}});
  const [newPost, setNewPost] = useState("");
  const [MyUserID, setMyUserID] = useState(-1);
  const [AllowNSFW, setAllowNSFW] = useState(false);
  const [ThisPostNSFW, setThisPostNSFW] = useState(false);
  const [isPosting, setIsPosting] = useState(false);

  // Check for user token on initial load
  // useEffect(() => {
  //   const token = Cookies.get('token');
  //   if (token) {
  //     axios.get(config.backend + '/valid', {
  //       headers: {
  //         'Authorization': `Bearer ${token}`
  //       }
  //     })      
  //     .then(res => {
  //       alert(JSON.stringify(res.data));
  //       setMyUserID(res.data);
  //     })
  //     // .catch(() => {
  //       // window.location.replace('/login');
  //     // })
  //     ;
  //   }
  // }, []);

  const handlePost = () => {
    const token = Cookies.get('token');
    if (!token) {
      window.location.replace('/login');
    } else {
      console.log(newPost);
      // setNewPost("");
    }
  };

  useEffect(() => {
    const token = Cookies.get('token');
    var myID = -1;
    if (token) {
      axios.get(config.backend + '/valid', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })      
      .catch(() =>
      {
        
      })
      .then(res => {
       try
       {
        // alert(JSON.stringify(res.data));
        myID = res.data.userId;
        setAllowNSFW(res.data.settings.allowNSFW);
       }
       catch(e) { }
       
        axios.get(config.backend + '/posts?myID=' + myID + '&page=0&onlyFollow=false')
        .then(res => {
          // alert(JSON.stringify(res.data));
          setData(res.data);
        })
        .catch(err => console.log(err));
      });
    }
    else
    {
      // todo: this code is copy-pasted. I don't feel like fixing it now, but you should probably use a GOTO here or something.
      axios.get(config.backend + '/posts?myID=' + myID + '&page=0&onlyFollow=false')
        .then(res => {
          // alert(JSON.stringify(res.data));
          setData(res.data);
        })
        .catch(err => console.log(err));
    }


  }, []);

  const makePost = () => {
    // alert(ThisPostNSFW);
    // return;

    const token = Cookies.get('token');

    const formData = new FormData();
    formData.append('Text', newPost);

    if (newPost === "" && selectedImages.length === 0)
    {
      alert("Post must have content");
      return;
    }

    setIsPosting(true);

    if (selectedImages.length === 0) {
        formData.append('Images', [null]); // Send an empty array if no images
      } else {
        // append images
        selectedImages.forEach((image, index) => {
          formData.append('Files', image.file);
        });
      }

      formData.append('NSFW', ThisPostNSFW);

    axios({
      method: 'post',
        url: config.backend + '/makepost',
      data: formData,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(res => {
      console.log(res);
        window.location.replace(config.frontend);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setIsPosting(false);
    });
  };

  const fileInput = useRef(); // create a ref

  const handleUploadClick = () => {
    fileInput.current.click(); // programmatically click the file input when button is clicked
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileSizeLimit = 20 * 1024 * 1024; // 20 MB limit
      if (file.size <= fileSizeLimit) {
        const reader = new FileReader();
        reader.onloadend = function() {
          const fileData = { data: reader.result, file, type: file.type };
          setSelectedImages(prev => [...prev, fileData]);
        };
        reader.readAsDataURL(file);
      } else {
        alert(`File size must be less than ${fileSizeLimit / 1024 / 1024}MB.`);
      }
    }
  };

  const handleImageClick = (index) => {
    setSelectedImages(prevImages => prevImages.filter((img, i) => i !== index));
  }

  const ToggleContainer = styled.div`
  display: ${AllowNSFW ? "flex" : "none"};
  align-items: center;
  justify-content: space-between;
  color: #fff;
  max-height: 35px;
  margin-right: 5px;
`;

return (
  <React.Fragment>
    <GlobalStyle />
    <Navigation />
    <SearchBar />
    <StyledFeed>
      <PostList posts={data.posts} users={data.users} />
    </StyledFeed>
    <MobileNav />
  </React.Fragment>
);
}

export default Explore;