import React from 'react';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook
import axios from 'axios';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import Navigation from './Navigation';
import config from './config';
import { useEffect, useState, useRef } from 'react';
import FullscreenImageViewer from './FullscreenImageViews';
import useIntersectionObserver from './useIntersectionObserver';
import VideoComponent from './CustomVideoPlayer';
import PostBox from './PostBox';

const DisplayName = styled(Link)`
  font-weight: bold;
  margin-right: 5px;
  color: white;
  text-decoration: none;
`;

const PostText = styled.p`
  margin: 0px;
  flex: 1;
  white-space: pre-wrap;
`;

const PostTime = styled.span`
  font-size: 0.8em;
  color: grey;
`;

const PostTimeLink = styled.a`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;


const Post = styled.div`
  position: relative;
  background-color: #06060e;
  display: flex;
  padding: 10px;
  color: #FFF;
  padding-left: 0;

  &:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
  }
`;

const UserName = styled.span`
  margin: 0;
  color: #aaa;
  margin-right: 5px;
`;

const ProfilePicture = styled.img`
width: 40px; // Set the width
height: 40px; // Set the height to maintain a square aspect ratio
min-width: 40px;
min-height: 40px;
border-radius: 50%; // Make the image circular
object-fit: cover; // Ensure the image covers the full area, cropping as needed
object-position: center; // Center the image within the element
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* This makes the height equal to the width */
  overflow: hidden;
  position: relative;
  border-radius: 5px;
`;

const HeartImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 3px;
  cursor: pointer;
`;

const LikeCount = styled.span`
  font-size: 0.8em;
  color: white;
  display: inline-block;
`;

const LikeSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

const ShowMoreButton = styled.span`
  display: block;
  color: orange;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ReplyImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 3px;
  cursor: pointer;
`;

const ReplyCount = styled.span`
  font-size: 0.8em;
  color: white;
  display: inline-block;
`;

const CopyImage = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
`;

const RepliesSection = styled.div`
  display: flex;
  align-items: center;
`;

const LikeSectionInner = styled.div`
  display: flex;
  align-items: center;
`;

const CopyUrlSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CopyUrlButton = ({ postId }) => {
  const handleCopy = () => {
    const postUrl = `https://yipyap.io/post/${postId}`;

    navigator.clipboard.writeText(postUrl).then(() => {
      console.log("Copied Yap URL");
    });
  };

  return (
    <CopyImage src={config.backend + "/resource/quotes.png"} alt="Copy URL" onClick={handleCopy} />
  );
};


const processLineText = (line) => {
    const words = line.split(/(\s+)/);
  
    return words.map((word, index) => {
      if (word.startsWith('@')) {
        const username = word.substring(1);
        return (
          <a key={index} href={`https://yipyap.io/${username}`} style={{ color: 'orange', textDecoration: 'none' }}>
            {word}
          </a>
        );
      }
  
      if (word.startsWith('#')) {
        const hash = word.substring(1);
  
        const isNumeric = /^[0-9]+$/.test(hash);
  
        if (!isNumeric) {
          return (
            <Link key={index} to={`/search?query=${hash}`} style={{ color: 'orange', textDecoration: 'none' }}>
              {word}
            </Link>
          );
        }
      }
  
      return word;
    });
  };

const processPostText = (text) => {
  const lines = text.split(/\n/);

  return lines.map((line, lineIndex) => {
    let processedLine = line;

    if (processedLine.startsWith('\\# ')) {
      processedLine = processedLine.replace(/^\\#/, '# ');
    } else if (processedLine.startsWith('\\## ')) {
      processedLine = processedLine.replace(/^\\##/, '## ');
    } else if (processedLine.startsWith('\\### ')) {
      processedLine = processedLine.replace(/^\\###/, '### ');
    } 

    else if (processedLine.startsWith('### ')) {
      return <h3 style={{margin: '0'}} key={lineIndex}>{processLineText(processedLine.slice(4))}</h3>;
    } else if (processedLine.startsWith('## ')) {
      return <h2 style={{margin: '0'}} key={lineIndex}>{processLineText(processedLine.slice(3))}</h2>;
    } else if (processedLine.startsWith('# ')) {
      return <h1 style={{margin: '0'}} key={lineIndex}>{processLineText(processedLine.slice(2))}</h1>;
    }

    return <p style={{margin: '0'}} key={lineIndex}>{processLineText(processedLine)}</p>;
  });
};

function timeSince(date) {
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);
  
    interval = Math.floor(seconds / 86400);
    if (interval >= 1)
        return format(date, 'MMMM do, yyyy');
    interval = Math.floor(seconds / 3600);
    if (interval >= 1)
        return interval + "h ago";
    interval = Math.floor(seconds / 60);
    if (interval >= 1)
        return interval + "m ago";
  
    return Math.floor(seconds) + "s ago";
  }

function PostItem({ post, user, liked, likeCount, handleLikeToggle, expanded, handleExpand }) {
    const navigate = useNavigate();
    const postRef = useRef(null);
    const [showButton, setShowButton] = useState(false);
    const [showHowlMenu, setShowHowlMenu] = useState(false);
    const [showQuoteBox, setShowQuoteBox] = useState(false);
    const [newPost, setNewPost] = useState("");
    const [isPosting, setIsPosting] = useState(false);
    const quoteBoxRef = useRef(null);

    const handleHowlClick = (postId) => {
      alert("Howling " + postId);
      const token = Cookies.get('token');
      const formData = new FormData();
      formData.append('Text', `<Howl ${postId}>`);
      formData.append('Images', [null]);
  
      formData.append('NSFW', false);
      formData.append('Tags', []);
  
      axios({
        method: 'post',
        url: config.backend + '/makepost',
        data: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .catch(err => console.log(err));
    }

    const handleQuoteClick = () => {
      setShowHowlMenu(false);
      setShowQuoteBox(true);
    }

    const handleBackgroundClick = () => {
      setShowQuoteBox(false);
    }

    useEffect(() => {
      if (showQuoteBox && quoteBoxRef.current) {
        quoteBoxRef.current.focus();
      }
    }, [showQuoteBox]);

    useEffect(() => {
      // Only run the effect if the post is not already expanded
      if (!expanded) {
        const postHeight = postRef.current.offsetHeight;
        const viewportHeight = window.innerHeight;
    
        // If the post content height is greater than a portion of the viewport height
        if (postHeight > viewportHeight * 0.3) {
          const images = postRef.current.querySelectorAll('img');
          const videos = postRef.current.querySelectorAll('video');
          let overlap = false;
    
          images.forEach((img) => {
            const imgBottom = img.getBoundingClientRect().bottom;
            if (imgBottom > viewportHeight * 0.3) {
              overlap = true;
            }
          });
    
          videos.forEach((video) => {
            const videoBottom = video.getBoundingClientRect().bottom;
            if (videoBottom > viewportHeight * 0.3) {
              overlap = true;
            }
          });
    
          // Trigger expand only if the post is not already expanded and overlaps the viewport
          if (overlap) {
            handleExpand(post.PostID); // Mark the post as expanded
          } else {
            setShowButton(true); // Show the "Show full Yap" button
          }
        } else {
          // If the post is smaller than the viewport, automatically expand
          handleExpand(post.PostID);
        }
      }
      // Add expanded as a dependency to ensure the effect only runs when it changes
    }, [expanded, handleExpand, post.PostID]);

    const handlePostClick = (event) => {
      if (event.target.tagName !== 'DIV'
      ) {
        return;
      }

      navigate(`/post/${post.PostID}`);
    };

    const numImages = post.Images.length;
    const numColsRows = Math.ceil(Math.sqrt(numImages));

    const Grid = styled.div`
      display: grid;
      grid-template-columns: repeat(${numColsRows}, 1fr);
      grid-gap: 5px;
      max-width: 100%;
      max-height: 100%;
      max-width: 500px;
    `;

    const ImageContainer = styled.div`
      width: 100%;
      height: 0;
      padding-bottom: 100%; // This ensures the aspect ratio of the container is kept square
      overflow: hidden;
      position: relative;
    `;

    const VideoContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    margin: 0 auto;
    border-radius: 20px;
`;

const PostContent = styled.div`
  margin-left: 10px;
  width: 100%;
`;

const PostInside = styled.div`
  position: relative;
  max-height: ${({ expanded }) => (expanded ? 'none' : '30vh')};
  overflow: hidden;
  mask-image: ${({ expanded }) =>
    expanded
      ? 'none'
      : 'linear-gradient(to bottom, black 70%, transparent 100%)'};
  transition: max-height 0.3s ease, mask-image 0.3s ease;
`;

const PostTextWrapper = styled.div`
  max-height: ${({ expanded }) => (expanded ? 'none' : '30vh')}; /* Restrict height only for the text */
  overflow: hidden;
  mask-image: ${({ expanded }) =>
    expanded
      ? 'none'
      : 'linear-gradient(to bottom, black 70%, transparent 100%)'};
  transition: max-height 0.3s ease, mask-image 0.3s ease;
`;

const HowlsSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
`;

const HowlMenu = styled.div`
  position: absolute;
  top: 25px;
  left: 0;
  width: 120px;
  background-color: #06060e;
  border: 1px solid #332f29;
  border-radius: 5px;
  z-index: 1000;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const HowlMenuItem = styled.div`
  padding: 10px;
  color: white;
  cursor: pointer;
  border-bottom: 1px solid #332f29;

  &:hover {
    background-color: #333;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const QuoteBoxContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PostBoxWrapper = styled.div`
  width: 80%;
  background-color: #111;
  color: white;
  padding: 20px;
  border-radius: 5px;
`;

const QuoteBox = styled.input`
  width: 80%;
  height: 100px;
  background-color: #111;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
`;

const PostButton = styled.button`
  padding: 10px 20px;
  background-color: orange;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.1s ease-in-out;
  
  &:hover {
    background-color: #ffba3b;
  }

  display: inline-block;
  vertical-align: middle;
`;

const HowlIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 3px;
  cursor: pointer;
`;

    const Image = styled.img`
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4%;
    `;

    const useIntersectionObserver = (options) => {
      const [isIntersecting, setIntersecting] = useState(false);
      const ref = useRef(null);
    
      useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
          setIntersecting(entry.isIntersecting);
        }, options);
    
        if (ref.current) {
          observer.observe(ref.current);
        }
    
        return () => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        };
      }, [ref, options]);
    
      return [ref, isIntersecting];
    };

    const [isViewerOpen, setViewerOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const openImageViewer = (index) => {
      setCurrentImageIndex(index);
      setViewerOpen(true);
    };



  const makePost = (postId) => {
    const token = Cookies.get('token');
    const formData = new FormData();
    formData.append('Text', newPost + "<Quote " + postId + ">");
    const selectedImages = []; // todo
    const ThisPostNSFW = false; // todo: don't allow user to turn off mature if quoted post is mature
    const tags = [];
    if (newPost === "" && selectedImages.length === 0) {
      alert("Post must have content");
      return;
    }

    setIsPosting(true);
    if (selectedImages.length === 0) {
      formData.append('Images', [null]);
    } else {
      selectedImages.forEach((image, index) => {
        formData.append('Files', image.file);
      });
    }

    formData.append('NSFW', ThisPostNSFW);
    formData.append('Tags', JSON.stringify(tags));

    axios({
      method: 'post',
      url: config.backend + '/makepost',
      data: formData,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(res => {
      window.location.reload();
    })
    .catch(err => console.log(err))
    .finally(() => {
      setIsPosting(false);
      setShowQuoteBox(false);
      setNewPost("");
    });
  };

  const changeQuoteText = (e) => {
    setNewPost(e.target.value);
  }


    const handleCopy = ({ postId}) => {
      const postUrl = `https://yipyap.io/post/${postId}`;

      navigator.clipboard.writeText(postUrl).then(() => {
        console.log("Copied Yap URL");
      });
    };
  
    return (
      <Post
        // onClick={handlePostClick}
        >
        {/* onClick={onClick} */}

        {showQuoteBox && (
          <QuoteBoxContainer visible={showQuoteBox} onClick={handleBackgroundClick}>
            <PostBoxWrapper onClick={(e) => e.stopPropagation()}>
              <PostBox style={{'z-index': 10000}} AllowNSFW={true} Quote={post.PostID} isQuote={true} /> {/* todo: fix AllowNSFW (check user settings) */}
            </PostBoxWrapper>
          </QuoteBoxContainer>
        )}
        {/* <QuoteBoxContainer visible={showQuoteBox} onClick={handleBackgroundClick}>
            <QuoteBox
              type="text"
              ref={quoteBoxRef}
              value={newPost}
              onChange={changeQuoteText}
              placeholder="Quote this..."
              onClick={(e) => e.stopPropagation()}
              autoFocus
              />
            <PostButton onClick={() => makePost(post.PostID)} disabled={isPosting}>Post</PostButton>
          </QuoteBoxContainer> */}

        <ProfilePicture src={user.ProfilePictureURL} alt={user.Username}/>
        <PostContent>
          <DisplayName to={`/${user.Username}`}>{user.DisplayName}</DisplayName>
          <UserName>@{user.Username}</UserName>
          <PostTimeLink href={`/${user.Username}/${post.PostID}`}>
            <PostTime>{timeSince(new Date(post.PostTime * 1000))}</PostTime>
          </PostTimeLink>

          <PostInside ref={postRef} expanded={expanded}>
            <PostTextWrapper expanded={expanded}>
              <PostText ref={postRef}>{processPostText(post.Text)}</PostText>
            </PostTextWrapper>
            {post.Images && post.Images.length > 0 &&
              <Grid>
                {post.Images.slice(0, 4).map((mediaSrc, index) => {
                  const isVideo = mediaSrc.endsWith('.mp4') || mediaSrc.endsWith('.mov');
                  
                  return isVideo ? (
                    <VideoContainer key={index}>
                      <VideoComponent src={config.backend + `/image/${mediaSrc}`} />
                    </VideoContainer>
                  ) : (
                    <ImageContainer key={index} onClick={() => openImageViewer(index)}>
                      <Image src={config.backend + `/image/${mediaSrc}`} alt="" />
                    </ImageContainer>
                  );
                })}
              </Grid>
            }
          </PostInside>
          {isViewerOpen && (
            <FullscreenImageViewer
              images={post.Images.map(imgSrc => config.backend + `/image/${imgSrc}`)}
              currentIndex={currentImageIndex}
              onClose={() => setViewerOpen(false)}
            />
          )}
          {showButton && !expanded && (
            <ShowMoreButton onClick={() => handleExpand()}>Show full Yap</ShowMoreButton>
          )}
          {/* <LikeSection>
          <RepliesSection>
            <LikeCount>{post.replies}</LikeCount>
            <ReplyImage src={config.backend + "/resource/quotes.png"} alt="Replies" />
          </RepliesSection>
            <LikeCount>{likeCount}</LikeCount>
            <HeartImage 
              src={liked ? config.backend + "/resource/heart-filled.png" : config.backend + "/resource/heart.png"} 
              alt="Heart" 
              onClick={handleLike}
            />
          </LikeSection> */}

          <LikeSection>
            <RepliesSection>
              <ReplyImage src={config.backend + "/resource/quotes.png"} alt="Replies" />
              <ReplyCount>{post.replies}</ReplyCount>
            </RepliesSection>

            <HowlsSection onClick={() => setShowHowlMenu(!showHowlMenu)}>
              <HowlIcon src={config.backend + "/resource/quotes.png"} alt="Howls" />
              <LikeCount>0</LikeCount>
              <HowlMenu visible={showHowlMenu}>
                <HowlMenuItem onClick={() => handleHowlClick(post.PostID)}>Howl</HowlMenuItem>
                <HowlMenuItem onClick={handleQuoteClick}>Quote</HowlMenuItem>
              </HowlMenu>
            </HowlsSection>

            <LikeSectionInner>
              <HeartImage 
                src={liked ? config.backend + "/resource/heart-filled.png" : config.backend + "/resource/heart.png"} 
                alt="Heart" 
                onClick={() => handleLikeToggle(post.PostID)}
              />
              <LikeCount>{likeCount}</LikeCount>
            </LikeSectionInner>

            <CopyUrlSection onClick={() => handleCopy(post.PostID)}>
              <CopyImage src={config.backend + "/resource/quotes.png"} alt="Copy URL" />
            </CopyUrlSection>
          </LikeSection>
        </PostContent>
      </Post>
    );
  }

  export default PostItem;