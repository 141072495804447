import React from 'react';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook
import axios from 'axios';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import Navigation from './Navigation';
import config from './config';
import { useEffect, useState, useRef } from 'react';
import FullscreenImageViewer from './FullscreenImageViews';
import useIntersectionObserver from './useIntersectionObserver';
import VideoComponent from './CustomVideoPlayer';
import PostBox from './PostBox';
import PostItem from './PostItem';

export const PostImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;
  
  function PostList({ posts, users }) {
    const [likeStatus, setLikeStatus] = useState({});
    const [listExpand, setListExpand] = useState({});

    useEffect(() => {
      const status = {};
      const expandedStatus = {};

      posts.forEach(p => {
        status[p.PostID] = {
          liked: p.iLiked,
          likeCount: p.LikeCount,
        };
        expandedStatus[p.PostID] = {
          expanded: false,
        };
      });
      setLikeStatus(status);
      setListExpand(expandedStatus);
    }, [posts]);

    const handleLike = async (postId) => {
      setLikeStatus(prevState => {
        const newState = { ...prevState };
        newState[postId].liked = !newState[postId].liked;

        newState[postId].likeCount += newState[postId].liked ? 1 : -1;

        return newState;
      });

      await likePost(postId);
    };

    const handleExpand = (postId) => {
      setListExpand((prevState) => ({
        ...prevState,
        [postId]: !prevState[postId],
      }));
    };

    async function likePost(postId) {
      try {
        const response = await axios({
          method: 'post',
          url: config.backend + '/dolike',
          data: {
            postId: postId,
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + Cookies.get('token'),
          },
        });
      } catch (error) {
        console.error(error); // Log any errors to the console
      }
    }

    const Divider = styled.div`
      height: 1px;
      background-color: #332f29;
      margin: 0px auto;

      @media (min-width: 768px) {
      width: 90%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    `;
  
    const handlePostClick = (username, postId) => {
      posts = [];
      window.location.assign(`/${username}/${postId}`);
    };
  
    return (
      posts.map((post) => (
        <React.Fragment key={post.PostID}>
          <PostItem 
            post={post}
            user={users[post.PosterId]}
            liked={likeStatus[post.PostID]?.liked}
            likeCount={likeStatus[post.PostID]?.likeCount}
            handleLikeToggle={() => handleLike(post.PostID)}
            expanded={!!listExpand[post.PostID]}
            handleExpand={() => handleExpand(post.PostID)}
            // onClick={() => handlePostClick(users[post.PosterId].Username, post.PostID)}
          />
          <Divider />
        </React.Fragment>
      ))
    );
  }
  
  export default PostList;