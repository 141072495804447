import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';
import Cookies from 'js-cookie';
import Navigation from './Navigation';
import MobileNav from './MobileNav';
import PostList from './PostList';
import config from './config';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto !important;
  border: 1px solid #332f29;
  padding: 20px;
  background-color: #06060e;

  @media (max-width: 768px) {
    width: auto;
    margin: 0;
  }
`;

const FollowButton = styled.button`
  background-color: ${props => props.isFollowing ? 'orange' : 'transparent'};
  color: ${props => props.isFollowing ? 'white' : 'orange'};
  border: 1px solid orange;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => props.isFollowing ? 'red' : 'transparent'};
    color: ${props => props.isFollowing ? 'white' : 'orange'};
    border-color: ${props => props.isFollowing ? 'red' : 'orange'};
  }
`;

const ProfilePicture = styled.img`
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

const DisplayName = styled.h1`
  font-weight: bold;
  margin: 0 0 5px 0;
  color: white;
  text-decoration: none;
  font-size: 2em;
`;

const UserName = styled.span`
  margin: 0 0 10px 0; // Adjust margin as needed
  color: #aaa;
`;

const EditableField = styled.input`
  margin: 10px 0;
  color: #aaa;
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
  margin-left: auto;
  position: relative;
`;

const OptionsButton = styled.button`
  background: transparent;
  border: 1px orange;
  border-radius: 50%;
  color: orange;
  font-size: 1.5em;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
  background: orange;
  }
`;

const OptionsMenu = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #060606e;
  border: 1px solid #332f29;
  broder-radius: 5px;
  padding: 10px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  z-index: 1000;
`;

const MenuItem = styled.button`
  background: none;
  border: none;
  color: white;
  padding: 10px 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  &:hover {
    background-color: #333;
  }
`;

const EditButton = styled.button`
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
`;

const UserDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px; // Add small left margin
  margin-bottom: 20px;
`;

const BioText = styled.p` // New styled component for bio text
  color: white; // Make bio text white
  margin: 0; // Adjust margin as needed
`;

const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const SortButton = styled.button`
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 1em;
`;

const SortingFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
`;

const FilterButton = styled.button`
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 1em;

  @media (min-width: 769px) {
    display: none;
  }
`;

const SortMenuItem = styled.div`
  padding: 10px;
  color: white;
  cursor: pointer;
  border-bottom: 1px solid #332f29;

  &:hover {
    background-color: #333;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const SortingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #06060e;
  padding: 10px;
  border-left: 1px solid #332f29;
  flex-grow: 1;

  @media (max-width: 768px) {
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    position: absolute;
    right: 0;
    top: 50px;
    width: 80%;
    background-color: #06060e;
    padding: 20px;
    border: 1px solid #332f29;
    z-index: 1000;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SortingLabel = styled.label`
  color: white;
  margin-right: 10px;
`;

const SortMenu = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  width: 100px;
  background-color: #06060e;
  border: 1px solid #332f29;
  border-radius: 5px;
  z-index: 1000;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
`;

function UserProfile() {
  const { username } = useParams();
  const [data, setData] = useState({user: {}, posts: []});
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({DisplayName: "", Bio: "", UserId: -1});
  const loggedInUsername = Cookies.get('username');
  const authToken = Cookies.get('token');
  const [isFollowing, setIsFollowing] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [userFound, setUserFound] = useState(true);

  // filtering options
  // todo: when Howling is fully implemented, make sure that howls follow these sorts as well
  const [photosOnly, setPhotosOnly] = useState(false);
  const [noPhotos, setNoPhotos] = useState(false);
  const [videosOnly, setVideosOnly] = useState(false);
  const [noVideos, setNoVideos] = useState(false);
  const [howlsOnly, setHowlsOnly] = useState(false);
  const [noHowls, setNoHowls] = useState(false);
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);

  // sorting options
  const [sortMenuVisible, setSortMenuVisible] = useState(false);
  const [sortMode, setSortMode] = useState(0); // 0 = new, 1 = top, 2 = oldest, 3 = explore

  const filteredPosts = data.posts.filter(post => {
    const hasPhoto = post.Images.some(img => /\.(jpg|jpeg|png|gif)$/i.test(img));
    const hasVideo = post.Images.some(img => /\.(mp4|mov)$/i.test(img));
    const isHowl = /^<Howl \d+>$/.test(post.Text);

    if (photosOnly && !hasPhoto)
      return false;
    if (noPhotos && hasPhoto)
      return false;

    if (videosOnly && !hasVideo)
      return false;
    if (noVideos && hasVideo)
      return false;

    if (howlsOnly && !isHowl)
      return false;
    if (noHowls && isHowl)
      return false;

    return true;
  })
  .sort((a, b) => {
    if (sortMode === 0) {
      return new Date(b.PostTime) - new Date(a.PostTime);
    } else if (sortMode === 1) {
      return b.LikeCount - a.LikeCount;
    } else if (sortMode === 2) {
      return new Date(a.PostTime) - new Date(b.PostTime);
    } else if (sortMode === 3) {
      return a.LikeCount - b.LikeCount; // todo: make this have a proper algorithm at some point
    }
    return 0;
  });

  const handleSortButtonClick = () => {
    setSortMenuVisible(!sortMenuVisible);
  }

  const handleFilterButtonClick = () => {
    setFilterPanelVisible(!filterPanelVisible);
  }

  const handleSortModeChange = (mode) => {
    setSortMode(mode);
    setSortMenuVisible(false);
  }

  const handlePhotosOnlyChange = (e) => {
    setPhotosOnly(e.target.checked);
    if (e.target.checked)
      setNoPhotos(false);
  };

  const handleNoPhotosChange = (e) => {
    setNoPhotos(e.target.checked);
    if (e.target.checked)
      setPhotosOnly(false);
  };

  const handleVideosOnlyChange = (e) => {
    setVideosOnly(e.target.checked);
    if (e.target.checked)
      setNoVideos(false);
  };

  const handleNoVideosChange = (e) => {
    setNoVideos(e.target.checked);
    if (e.target.checked)
      setVideosOnly(false);
  };

  const handleHowlsOnlyChange = (e) => {
    setHowlsOnly(e.target.checked);
    if (e.target.checked)
      setNoHowls(false);
  };

  const handleNoHowlsChange = (e) => {
    setNoHowls(e.target.checked);
    if (e.target.checked)
      setHowlsOnly(false);
  };

  const handleFollowToggle = async () => {
    await axios({
      method: 'post',
      url: config.backend + '/dofollow',
      data: {
        userID: editableData.userId,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + Cookies.get('token'),
      },
    });

    setIsFollowing(!isFollowing);
  };
  
  const handleMuteUser = async () => {
    await axios({
      method: 'post',
      url: `${config.backend}/mute-user`,
      data: { userID: data.user.UserId },
      headers: { 'Authorization': `Bearer ${authToken}` },
    });
    setMenuVisible(false);
    alert('User muted successfully');
  };

  const handleBlockUser = async () => {
    await axios({
      method: 'post',
      url: `${config.backend}/block-user`,
      data: { userID: data.user.UserId },
      headers: { 'Authorization': `Bearer ${authToken}` },
    });
    setMenuVisible(false);
    alert('User blocked successfully');
  };

  useEffect(() => {
    const token = Cookies.get('token');
    var myID = -1;
    if (token) {
      axios.get(config.backend + '/valid', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })      
      .then(res => {
       myID = res.data.userId;
      })
      .catch(() => {
        window.location.replace('/login');
      })
      .finally(() => {
        // todo: copy-pasted code; please make not copy-pasted
        axios.get(config.backend + "/user?username=" + username + "&myID=" + myID)
        .then(res => {
          if (!res.data.user) {
            setUserFound(false);
          } else {
            setData(res.data);
            setEditableData({
              DisplayName: res.data.user.DisplayName,
              Bio: res.data.user.Bio, 
              userId: res.data.user.UserId,
            });
            setIsFollowing(res.data.user.iFollow);
          }
  
          // alert(JSON.stringify(res.data.user));
        })
        .catch(err => {
          setUserFound(false);
        });
      })
      ;
    }
    else
    {
      axios.get(config.backend + "/user?username=" + username + "&myID=" + myID)
    .then(res => {
      if (!res.data.user) {
        setUserFound(false);
      } else {
        setData(res.data);
        setEditableData({
          DisplayName: res.data.user.DisplayName, 
          Bio: res.data.user.Bio, 
          userId: res.data.user.UserId,
        });
        setIsFollowing(res.data.user.iFollow);
      }

      // alert(JSON.stringify(res.data.user));
    })
    .catch(err => {
      setUserFound(false);
    });
    }
    }, [username]);

    const handleFileChange = (e) => {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("image", file);
  
      axios.post(config.backend + "/setpfp", formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${authToken}`
          }
      });
  }
  
    const handleEditToggle = () => {
      if (isEditing) {
        axios({
          method: 'post',
          url: config.backend + '/edituser',
          data: editableData,
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        })
          // .then(res => {
          //   setData(res.data);
          // })
          .catch(err => console.log(err));
      }
      setIsEditing(!isEditing);
    }

    return (
      <React.Fragment>
        <GlobalStyle />
        <Navigation />
        <StyledFeed>
          <MainContainer>
            {!userFound && (
              <div style={{ margin: '0 auto !important', textAlign: 'center' }}>
                <h1 style={{ color: '#fff' }}>User not found</h1>
                <p style={{ color: '#fff' }}>Want to go back <a href='/' style={{ color: 'orange' }}>home</a>?</p>
              </div>
            )}
            {userFound && (
              <>
                <SortingFilterContainer>
                  <div style={{ position: 'relative' }}>
                    <SortButton onClick={handleSortButtonClick}>Sort</SortButton>
                    <SortMenu visible={sortMenuVisible}>
                    <SortMenuItem onClick={() => handleSortModeChange(0)}>New</SortMenuItem>
                    <SortMenuItem onClick={() => handleSortModeChange(1)}>Top</SortMenuItem>
                    <SortMenuItem onClick={() => handleSortModeChange(2)}>Old</SortMenuItem>
                    <SortMenuItem onClick={() => handleSortModeChange(3)}>Explore</SortMenuItem>
                    </SortMenu>
                  </div>
                  <FilterButton onClick={handleFilterButtonClick}>Filter</FilterButton>
                </SortingFilterContainer>
                {isEditing 
                  ? <input type="file" onChange={handleFileChange} />
                  : <ProfilePicture src={data.user.ProfilePictureURL} alt={data.user.Username}/>
                }
                <UserDetailContainer>
                  {isEditing 
                    ? <EditableField value={editableData.DisplayName} onChange={e => setEditableData({...editableData, DisplayName: e.target.value})}/>
                    : <DisplayName>{editableData.DisplayName}</DisplayName>
                  }
                  <UserName>@{data.user.Username}</UserName>
                  {isEditing 
                    ? <EditableField value={editableData.Bio} onChange={e => setEditableData({...editableData, Bio: e.target.value})}/>
                    : <BioText>{editableData.Bio}</BioText>
                  }
                </UserDetailContainer>
                {loggedInUsername !== username && (
                  <ButtonContainer>
                    <OptionsButton onClick={() => setMenuVisible(!menuVisible)}>...</OptionsButton>
                    <OptionsMenu visible={menuVisible}>
                      <MenuItem onClick={handleMuteUser}>Mute User</MenuItem>
                      <MenuItem onClick={handleBlockUser}>Block User</MenuItem>
                    </OptionsMenu>
                    <FollowButton isFollowing={isFollowing} onClick={handleFollowToggle}>
                      {isFollowing ? 'Following' : 'Follow'}
                    </FollowButton>
                  </ButtonContainer>
                )}
                {loggedInUsername === username && (
                  <ButtonContainer>
                    <EditButton onClick={handleEditToggle}>
                      {isEditing ? 'Save Changes' : 'Edit Profile'}
                    </EditButton>
                  </ButtonContainer>
                )}






                <SortingContainer>
                  <h3 style={{ color: "white" }}>Filter Yaps By</h3>
                  <CheckboxContainer>
                    <CheckboxGroup>
                      <SortingLabel>
                        <input
                          type="checkbox"
                          checked={photosOnly}
                          onChange={handlePhotosOnlyChange}
                        />
                        Photos only
                      </SortingLabel>
                      <SortingLabel>
                        <input
                          type="checkbox"
                          checked={noPhotos}
                          onChange={handleNoPhotosChange}
                        />
                        No photos
                      </SortingLabel>
                    </CheckboxGroup>

                    <CheckboxGroup>
                      <SortingLabel>
                        <input
                          type="checkbox"
                          checked={videosOnly}
                          onChange={handleVideosOnlyChange}
                        />
                        Videos only
                      </SortingLabel>
                      <SortingLabel>
                        <input
                          type="checkbox"
                          checked={noVideos}
                          onChange={handleNoVideosChange}
                        />
                        No videos
                      </SortingLabel>
                    </CheckboxGroup>

                    <SortingLabel>
                      <input
                        type="checkbox"
                        checked={howlsOnly}
                        onChange={handleHowlsOnlyChange}
                      />
                      Howls only
                    </SortingLabel>

                    <SortingLabel>
                      <input
                        type="checkbox"
                        checked={noHowls}
                        onChange={handleNoHowlsChange}
                      />
                      No howls
                    </SortingLabel>
                  </CheckboxContainer>
                </SortingContainer>
              </>
            )}

            </MainContainer>
          <PostList posts={filteredPosts} users={{[data.user.UserId]: data.user}} />
        </StyledFeed>
        <MobileNav />
      </React.Fragment>
    );
  }
  
  export default UserProfile;