import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const Nav = styled.nav`
  width: 20%;
  height: 25vh;
  position: fixed;
  top: 10%; // start slightly below the top of the screen
//   background: #333; // Dark grey background
  color: #fff; // White text
  border-radius: 10px;
  padding: 10px 0; // padding top and bottom

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // align items to the top
  align-items: center;
  height: 100%;
  width: 100%; // take full width of Nav
`;

const NavItem = styled.li`
  list-style-type: none;
  width: 100%; // take full width of NavList
`;

const NavigationLink = styled(Link)`
  display: inline-block;
  padding: 15px 30px;
  margin: 5px;
  color: #fff; // White link color
  text-decoration: none; // Remove underline
  background-color: transparent;
  border-radius: 50px;
  width: 100%; // take full width of NavItem
  box-sizing: border-box; // include padding and border in element's total width and height
  text-align: center; // center text
  transition: background-color 0.3s ease-in-out;
  
  &:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2); // Light grey color on hover
  }
`;

const Navigation = () => {
  const token = Cookies.get('token');
  const username = Cookies.get('username');

  return (
    <Nav>
      <NavList>
        <NavItem><NavigationLink to={ "/" }>Home</NavigationLink></NavItem>
          {token 
            ? <NavItem><NavigationLink to={ "/explore" }>Explore</NavigationLink></NavItem> 
            : null }

            {token
              ? <NavItem><NavigationLink to={ "/notifications" }>Notifications</NavigationLink></NavItem>
              : null }

        {token 
          ? <NavItem><NavigationLink to={ "/settings" }>Settings</NavigationLink></NavItem> 
          : null }
          
        {token 
          ? <NavItem><NavigationLink to={ "/" + username }>Profile</NavigationLink></NavItem> 
          : <NavItem><NavigationLink to={ "/login" }>Login</NavigationLink></NavItem> }
      </NavList>
    </Nav>
  );
};

export default Navigation;