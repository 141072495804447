import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import { useRef } from 'react'; // import useRef
import Cookies from 'js-cookie';
import PostList from './PostList';
import Navigation from './Navigation';
import MobileNav from './MobileNav';
import config from './config';
import {Helmet} from "react-helmet";
import VideoComponent from './CustomVideoPlayer';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
    // background-color: #aaa;
  }
`;

const DisplayName = styled(Link)`
  font-weight: bold;
  margin-right: 10px;
  color: white;
  text-decoration: none;
`;

const PostText = styled.p`
  margin: 5px;
  white-space: pre-wrap;
`;

const PostTime = styled.span`
  font-size: 0.8em;
  color: grey;
  margin-left: 5px;
`;

const Post = styled.div`
  background-color: #050507;
  display: flex;
  position: relative;
  color: #FFF;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
  }
`;

const PostButton = styled.button`
  padding: 10px 20px;
  background-color: orange;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.1s ease-in-out;
  
  &:hover {
    background-color: #ffba3b;
  }
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  cursor: pointer;
`;

const PostContent = styled.div`
  margin-left: 10px;
  padding-bottom: 30px;  // Add some padding to the bottom
  width:100%;
`;

const UserName = styled.span`
  margin: 0;
  color: #aaa;
`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;


const ImageWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
`;

const PostImage = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
position: absolute;
top: 0;
left: 0;
border-radius: 5px;
`;

const StyledFeed = styled.div`
  box-sizing: border-box;
  width: 40%;
  margin: 0 auto !important;
  padding: 20px;
  background-color: #06060e;
  border: 1px solid #332f29;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const TextBox = styled.textarea`
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  background-color: #111;
  border: none;
  outline: none; // remove outline when text box is focused
  resize: none;
  border-radius: 5px; // adjust this for the rounded corner effect
  color: white; // text color
  font-size: 18px;
  &::placeholder { // style the placeholder text
    color: lightgrey;
  }
`;

const FileInput = styled.input`
  display: none; // this hides the default file input
`;

const PostContainer = styled.div`
  position: relative;
`;

const UploadButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: orange;
  border: none;
  font-size: 1.5em;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function timeSince(date) {
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1)
        return interval + "y";
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1)
        return interval + "mo";
    interval = Math.floor(seconds / 86400);
    if (interval >= 1)
        return interval + "d";
    interval = Math.floor(seconds / 3600);
    if (interval >= 1)
        return interval + "h";
    interval = Math.floor(seconds / 60);
    if (interval >= 1)
        return interval + "m";

    return Math.floor(seconds) + "s";
  }

function SinglePost() {
  const { PostID } = useParams();
  const [post, setPost] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [newPost, setNewPost] = useState("");
  const [data, setData] = useState({posts: [], users: {}});

  const fileInput = useRef(); // create a ref

  const handleUploadClick = () => {
    fileInput.current.click(); // programmatically click the file input when button is clicked
  };

  useEffect(() => {
    axios.get(config.backend + `/posts?reply=${PostID}`)
      .then(res => {
        // alert(JSON.stringify(res.data));
        setData(res.data);
      })
      .catch(err => console.log(err));
    }, []);

  const makePost = () => {
    const token = Cookies.get('token');

    const formData = new FormData();
    formData.append('Text', newPost);

    if (newPost === "" && selectedImages.length === 0)
    {
      alert("Post must have content");
      return;
    }

    if (selectedImages.length === 0) {
        formData.append('Images', [null]); // Send an empty array if no images
      } else {
        // append images
        selectedImages.forEach((image, index) => {
          formData.append('Images', image.file);
        });
      }

      formData.append('ReplyTo', PostID);

    axios({
      method: 'post',
        url: config.backend + '/makepost',
      data: formData,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(res => {
      console.log(res);
      window.location.replace(config.frontend);
    })
    .catch(err => {
      console.log(err);
    });
  };

  const handleImageClick = (index) => {
    setSelectedImages(prevImages => prevImages.filter((img, i) => i !== index));
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // get the file
    if (file && file.size <= 2 * 1024 * 1024) { // size check for 2MB
      const reader = new FileReader();
      reader.onloadend = function() {
        setSelectedImages(prevImages => [...prevImages, { data: reader.result, file }]);
      }
      reader.readAsDataURL(file); // reads the data as a URL
    } else {
      alert("File size must be less than 2MB.");
    }
  };

  useEffect(() => {
    axios.get(config.backend + `/post/${PostID}`)
      .then(response => {
        // alert("Loaded: " + JSON.stringify(response.data));
        setPost(response.data.post);
        setUser(response.data.user); // Assuming the response also includes user data
      })
      .catch(error => {
        console.error("There was an error retrieving the post!", error);
      });
  }, [PostID]);

  function truncate(str, num) {
    return str.length > num ? str.slice(0, num) + "..." : str;
  }

  if (!post || !user) return null; // Return nothing if post or user is not yet loaded
  
  const numImages = post.Images.length;
  const numColsRows = Math.ceil(Math.sqrt(numImages));
  
  const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${numColsRows}, 1fr);
  grid-gap: 5px;
  max-width: 100%;
  max-height: 100%;
  max-width: 800px;
`;

const ImageContainer = styled.div`
width: 100%;
height: 0;
padding-bottom: 100%; // This ensures the aspect ratio of the container is kept square
overflow: hidden;
position: relative;
`;

const VideoContainer = styled.div`
width: 100%;
overflow: hidden;
position: relative;
  width: 100%;
  height: auto;
  object-fit: contain; // This will ensure the image fits within the container, maintaining its aspect ratio
  display: block; // Removes any extra space below the image
  margin: 0 auto; // Centers the image horizontally if it's narrower than the container
  border-radius: 20px;
`;

const Image = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
position: absolute;
top: 0;
left: 0;
border-radius: 5px;
`;

const useIntersectionObserver = (options) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isIntersecting];
};

  return (
    <React.Fragment>
      <Helmet>
        <title>"{truncate(post.Text, 15)}" - @{user.Username} on YipYap</title>
      </Helmet>

      <GlobalStyle />
      <Navigation />
      <StyledFeed>
          <Post>
        <ProfilePicture src={user.ProfilePictureURL} alt={user.Username} />
        <PostContent>
          <DisplayName to={`/${user.Username}`}>{user.DisplayName}</DisplayName>
          <UserName>@{user.Username}</UserName>
          <PostTime>{timeSince(new Date(post.PostTime * 1000))} ago</PostTime>
          <PostText>{post.Text}</PostText>
          {post.Images && post.Images.length > 0 &&
            <Grid>
            {post.Images.slice(0, 4).map((mediaSrc, index) => {
              const isVideo = mediaSrc.endsWith('.mp4') || mediaSrc.endsWith('.mov');
              
              return isVideo ? (
                <VideoContainer key={index}>
                  <VideoComponent src={config.backend + `/image/${mediaSrc}`} />
                </VideoContainer>
              ) : (
                <ImageContainer key={index}>
                  <Image src={config.backend + `/image/${mediaSrc}`} alt="" />
                </ImageContainer>
              );
            })}
          </Grid>
          }
        </PostContent>
      </Post>
      <FileInput 
            type="file" 
            accept=".png" 
            onChange={handleFileChange} 
            ref={fileInput}
          />
          <PostContainer>
              <TextBox 
              value={newPost} 
              onChange={e => setNewPost(e.target.value)}
              placeholder="Post a reply!"
              />
              {selectedImages.map((img, index) => (
            <ImagePreview src={img.data} alt="" onClick={() => handleImageClick(index)} />
          ))}
              <UploadButton onClick={handleUploadClick}>+</UploadButton>
          </PostContainer>
          <PostButton onClick={makePost}>Post</PostButton>
          <PostList posts={data.posts} users={data.users} />
      </StyledFeed>
      <MobileNav />
    </React.Fragment>
  );
}

export default SinglePost;